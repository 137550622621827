<template>
    <div class="page-container">
        <div class="box-container">
            <div class="box">
                <div class="box-title">
                    <p>{{ $t('certificates.generate_certificate') }}</p>
                </div>
                <div class="box-form">
                    <Form class="form" @submit="generateCertificate" :disabled="is_generating">
                        <FormInputSelect v-model="$v.certificate.type.$model" identifier="type"
                                         :label="$t('certificates.certificate')" :options="typeOptions"
                                         :placeholder="$t('certificates.certificate')" :disabled="is_generating"
                                         :has-error="$v.certificate.type.$error" track-by="id"
                                         display-label="name" :multiple="false">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.type.required">
                                    {{ $t('validation.x_are_required', {x: $t('certificates.certificate')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputText v-model="$v.certificate.name.$model" identifier="name"
                                       :label="$t('certificates.name_and_surname')" :disabled="is_generating"
                                       :has-error="$v.certificate.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.name_and_surname')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-if="certificate.type?.id === CERTIFICATE_TYPE.FOUNDATION_SOCIAL_WELFARE_SERVICES"
                                       v-model="$v.certificate.course_name.$model" identifier="course_name"
                                       :label="$t('certificates.course_name')" :disabled="is_generating"
                                       :has-error="$v.certificate.course_name.$error" autocomplete="off"
                                       :use-textarea="true"
                                       :large-textarea="true">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.course_name.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.course_name')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputText v-if="certificate.type?.id === CERTIFICATE_TYPE.FOUNDATION_SOCIAL_WELFARE_SERVICES"
                                       v-model="$v.certificate.company_name.$model" identifier="company_name"
                                       :label="$t('certificates.company_name')" :disabled="is_generating"
                                       :has-error="$v.certificate.company_name.$error" :use-textarea="true"
                                       :large-textarea="true">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.company_name.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.company_name')}) }}
                                </p>
                            </template>
                        </FormInputText>

                        <FormInputDateTime v-model="$v.certificate.date.$model" identifier="date"
                                           :label="$t('certificates.date')" :disabled="is_generating"
                                           position="top" :onlyDate="true" formatted="Do MMMM YYYY"
                                           :has-error="$v.certificate.date.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.certificate.date.required">
                                    {{ $t('validation.x_is_required', {x: $t('certificates.date')}) }}
                                </p>
                            </template>
                        </FormInputDateTime>


                        <Button type="submit" className="--primary --small ml-auto mt-4"
                                :class="{spinner: is_generating}">
                            {{ $t('certificates.generate') }}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
        <vue-html2pdf
            :float-layout="true"
            :show-layout="false"
            :enable-download="true"
            :filename="`${certificate.name} Certificate`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-orientation="certificate.type?.id === CERTIFICATE_TYPE.LIFE_SKILLS_DEVELOPMENT ? 'landscape' : 'portrait'"
            :pdf-content-width="certificate.type?.id === CERTIFICATE_TYPE.LIFE_SKILLS_DEVELOPMENT ? '1122px' : '800px'"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf">
            <FoundationSocialWelfareServicesCertificate
                v-if="certificate.type?.id === CERTIFICATE_TYPE.FOUNDATION_SOCIAL_WELFARE_SERVICES && certificate?.name && certificate?.date && certificate?.course_name"
                slot="pdf-content"
                :name="certificate?.name"
                :date="certificate?.date"
                :company_name="certificate?.company_name"
                :course_name="certificate?.course_name"
            />
            <LifeSkillsDevelopmentCertificate
                v-else-if="certificate.type?.id === CERTIFICATE_TYPE.LIFE_SKILLS_DEVELOPMENT && certificate?.name && certificate?.date"
                slot="pdf-content"
                :name="certificate?.name"
                :date="certificate?.date"
                />
        </vue-html2pdf>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import {required, requiredIf} from "vuelidate/lib/validators";
import FormInputText from "../../components/form/FormInputText";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import Form from "../../components/form/Form";
import VueHtml2pdf from 'vue-html2pdf'
import FormInputSelect from "../../components/form/FormInputSelect.vue";
import FoundationSocialWelfareServicesCertificate from "./FoundationSocialWelfareServicesCertificate.vue";
import LifeSkillsDevelopmentCertificate from "./LifeSkillsDevelopmentCertificate.vue";

const CERTIFICATE_TYPE = {
    FOUNDATION_SOCIAL_WELFARE_SERVICES: 'foundation-for-social-welfare-services',
    LIFE_SKILLS_DEVELOPMENT: 'life-skills-development',
}

export default {
    name: "certificates-index-page",
    components: {
        LifeSkillsDevelopmentCertificate,
        FoundationSocialWelfareServicesCertificate,
        FormInputSelect,
        FormInputDateTime,
        FormInputText,
        ConfirmModal,
        Select,
        Button,
        Search,
        Headbar,
        Form,
        VueHtml2pdf
    },
    data: function () {
        const typeOptions = [
            {id: CERTIFICATE_TYPE.FOUNDATION_SOCIAL_WELFARE_SERVICES, name: 'Foundation for Social Welfare Services'},
            {id: CERTIFICATE_TYPE.LIFE_SKILLS_DEVELOPMENT, name: 'Life Skills Development (MQF 2)'},
        ];

        return {
            CERTIFICATE_TYPE,
            certificate: {
                type: typeOptions[0],
                name: null,
                course_name: null,
                company_name: null,
                date: null,
            },
            is_generating: false,
            typeOptions,
        }
    },
    validations: {
        certificate: {
            type: {required},
            name: {required},
            course_name: {
                required: requiredIf(({type}) => {
                    return type?.id === CERTIFICATE_TYPE.FOUNDATION_SOCIAL_WELFARE_SERVICES
                })
            },
            company_name: {},
            date: {required},
        }
    },
    methods: {
        async generateCertificate() {
            this.$v.certificate.$touch();
            if (this.$v.certificate.$anyError || this.is_generating) return;

            this.is_generating = true;
            await this.$refs.html2Pdf.generatePdf()
        },
        async beforeDownload() {
            this.is_generating = true;
        },
        async hasDownloaded() {
            this.is_generating = false;
        },
    },
    mounted() {

    },
    head() {
        return {
            title: {
                inner: this.$t('nav.certificates')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply w-full h-full;

    .box-container {
        @apply flex flex-row w-full h-full py-8;

        .box {
            @apply m-auto bg-white rounded-lg w-full mx-8 max-w-xl text-grey-darkest;
            box-shadow: 5px 0px 10px #0000001A;

            @screen md {
                @apply w-1/2 mx-auto;
            }

            .box-title {
                @apply flex flex-row border-b border-grey-light py-4;

                p {
                    @apply font-bold ml-5;
                }
            }

            .box-form {
                @apply p-5;

                .form {
                    @apply flex flex-col;
                }
            }
        }
    }
}
</style>
