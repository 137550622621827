<script>
export default {
  name: "FoundationSocialWelfareServicesCertificate",
  props: {
    name: {
      type: String,
      required: true,
    },
    course_name: {
      type: String,
      required: true,
    },
    company_name: {
      type: String,
    },
    date: {
      type: String,
      required: true,
    },
  },
  methods: {
    parsedText(text) {
      return text.replace(/\r\n|\r|\n/g, '<br>');
    },
  }
}
</script>

<template>
  <section class="certificate">
    <div class="printable-certificate">
      <div class="printable-container">
        <div class="stripes-container">
          <div class="long-stripe"></div>
          <div class="short-stripe orange"></div>
          <div class="short-stripe red"></div>
          <div class="short-stripe green"></div>
          <div class="short-stripe blue"></div>
        </div>
        <div class="info-container">
          <div class="logo-container">
            <img alt="logo" src="../../assets/logo-no-text.png"/>
            <p>Foundation for Social Welfare Services</p>
            <p>Here for you</p>
          </div>
          <div class="subtitle-container collaboration-container" v-if="company_name">
            <p>In collaboration with</p>
            <p v-html="parsedText(company_name)"/>
          </div>
          <div class="certificate-of-attendance-container">
            <p>CERTIFICATE</p>
            <p>of Attendance</p>

          </div>
          <div class="subtitle-container" v-if="name">
            <p>This certificate is presented to</p>
            <p>{{ name }}</p>
          </div>
          <div class="course-covered-container" v-if="course_name">
            <p>S.A.F.E Programme topic/s covered:</p>
            <p v-html="parsedText(course_name)"/>
          </div>
          <div class="date-and-signature-container" v-if="date">
            <div class="date-container">
              <p>{{ $moment(date).format('Do MMMM YYYY') }}</p>
              <p>Date</p>
            </div>
            <div class="signature-container">
              <img alt="signature" src="../../assets/pdf-signature.png">
              <p>Charles Scerri</p>
              <p>Sedqa Operations Director</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.certificate {
  .printable-certificate {
    @apply w-full flex flex-row;
    height: 1122px;

    .printable-container {
      @apply w-full h-full flex flex-row;

      .stripes-container {
        @apply h-full flex flex-col bg-black;
        width: 1%;

        .long-stripe {
          @apply bg-certificate-grey w-full;
          height: 50%;
        }

        .short-stripe {
          @apply w-full;
          height: 12.5%;

          &.orange {
            @apply bg-certificate-orange;
          }

          &.red {
            @apply bg-certificate-red;
          }

          &.green {
            @apply bg-certificate-green;
          }

          &.blue {
            @apply bg-certificate-blue;
          }
        }
      }

      .info-container {
        @apply h-full w-full flex flex-col bg-white gap-y-14 pt-20 pb-12;
        width: 99%;
        background-image: url('../../../src/assets/certificate-bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        font-family: 'Harmonia', serif !important;

        .logo-container {
          @apply flex flex-col ml-auto mr-4 pr-16;

          img {
            @apply h-40 mb-4 mx-auto;
          }

          p {
            @apply text-certificate-text-grey;
            font-family: 'Harmonia', serif !important;

            &:nth-of-type(1) {
              @apply font-bold text-lg;
            }

            &:nth-of-type(2) {
              @apply mx-auto text-sm;
            }
          }
        }

        .subtitle-container {
          @apply flex flex-col ml-auto pr-16;

          &.collaboration-container {
            @apply mr-12;
          }

          p {
            @apply text-certificate-text-grey mr-auto text-lg;
            font-family: 'Harmonia', serif !important;

            &:nth-of-type(2) {
              @apply text-black;
            }
          }
        }

        .certificate-of-attendance-container {
          @apply flex flex-col ml-auto pr-16 -mt-2;

          p {
            @apply text-certificate-text-grey mr-auto;

            &:nth-of-type(1) {
              @apply font-bold text-6xl;
              font-family: 'Harmonia-Bold', serif !important;
            }

            &:nth-of-type(2) {
              @apply text-4xl ml-auto mr-0 italic;
              font-family: 'Harmonia-Italic', serif !important;
            }
          }
        }

        .course-covered-container {
          @apply flex flex-col mx-auto gap-y-3;

          p {
            @apply text-center;
            font-family: 'Harmonia', serif !important;

            &:nth-of-type(2) {
              @apply mx-auto font-bold;
            }
          }
        }

        .date-and-signature-container {
          @apply flex flex-row justify-between pl-8 mt-auto pr-16;

          .date-container {
            @apply flex flex-col mt-auto mb-6;

            p {
              font-family: 'Harmonia', serif !important;

              &:nth-of-type(1) {
                @apply border-b border-certificate-text-grey font-bold px-2 pb-2;
              }

              &:nth-of-type(2) {
                @apply text-certificate-text-grey;
              }
            }
          }
        }

        .signature-container {
          @apply flex flex-col;

          img {
            @apply h-24 pb-2 mx-auto border-certificate-text-grey;
          }

          p {
            font-family: 'Harmonia', serif !important;

            &:nth-of-type(1) {
              @apply border-t border-certificate-text-grey font-bold px-2 ml-auto pl-24;
            }

            &:nth-of-type(2) {
              @apply text-certificate-text-grey;
            }
          }
        }
      }
    }
  }
}
</style>