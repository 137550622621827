<script>
export default {
    name: "LifeSkillsDevelopmentCertificate",
    props: {
        name: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
    },
}
</script>

<template>
    <div>
        <section class="certificate">
            <div class="printable-certificate with-background">
                <h1>AĠENZIJA SEDQA</h1>
                <p class="certifies">THIS CERTIFIES THAT</p>
                <p class="name">{{ name.toUpperCase() }}</p>
                <p class="achieved">has successfully achieved a</p>
                <h2>LIFE SKILLS DEVELOPMENT (MQF 2)</h2>
                <p class="directorate">
                    Approved by the Directorate of Standards and Quality in Education, Ministry for Education,<br/>
                    Sport, Youth, Research and Innovation at <b>MQF Level 2</b> (1 CREDIT)
                </p>
                <div class="sign-container">
                    <div class="sign-cell-container">
                        <div class="field-value">
                            <img class="signature" alt="signature" src="../../assets/pdf-signature.png">
                        </div>
                        <p class="field-title">
                            Operations Director<br/>
                            Aġenzija Sedqa
                        </p>
                    </div>
                    <div class="sign-cell-container">
                        <div class="field-value">{{ $moment(date).format('Do MMMM YYYY') }}</div>
                        <p class="field-title">
                            Date
                        </p>
                    </div>
                </div>
                <div class="footer-area">
                    <div class="footer">
                        <img src="../../assets/logo.png" class="logo" alt="logo"/>
                        <img src="../../assets/malta.png" class="malta" alt="malta"/>
                    </div>
                    <div class="footer kids">
                        <div class="kids-container">
                            <img src="../../assets/kids-1.png" class="kid kid-1" alt="kids 1"/>
                            <p class="tfal">T.F.A.L. 3</p>
                        </div>

                        <div class="kids-container">
                            <img src="../../assets/kids-2.png" class="kid kid-2" alt="kids 2"/>
                            <p class="tfal">T.F.A.L. 5</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="html2pdf__page-break"></div>
        <section class="certificate">
            <div class="printable-certificate">
                <p class="referencing-report">Referencing Report – Malta Qualification Framework Guidelines</p>
                <table>
                    <tr>
                        <td>8</td>
                        <td colspan="2">Doctoral Degree</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td colspan="2">Master’s Degree/ Post-Graduate Diploma/ Post Graduate Certificate</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td colspan="2">Bachelor’s Degree</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Undergraduate Diploma/ Undergraduate Certificate</td>
                        <td>VET Higher Diploma Foundation Degree</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Marticulation Certificate/ Advanced level/ Intermediate Level</td>
                        <td>VET Diploma</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>General education/ SEC education 1 - 5</td>
                        <td>VET Level 3</td>
                    </tr>
                    <tr class="prominent">
                        <td>2</td>
                        <td>General Education/ SEC Education 6 – 7</td>
                        <td>VET Level 2</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>General Education Level 1/ School Leaving Certificate</td>
                        <td>VET Level 1</td>
                    </tr>
                    <tr>
                        <td>B</td>
                        <td colspan="2">Introductory Level B</td>
                    </tr>
                    <tr>
                        <td>A</td>
                        <td colspan="2">Introductory Level A</td>
                    </tr>
                </table>
                <p class="reference-subtitle">Referencing Report, 2016</p>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
.certificate {
    width: 1060px;
    margin: 24px auto;
    height: 744px;
    display: flex;
    justify-content: center;

    .printable-certificate {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.with-background {
            background-image: url('../../../src/assets/background.png');
            background-repeat: no-repeat;
            background-position: left;
            background-size: contain;
        }

        & h1 {
            margin-top: 4px;
            margin-bottom: 20px;
            color: #415469;
            font-size: 32pt;
            font-family: "Bahnschrift", sans-serif;
            font-style: 600;
            font-weight: normal;
            text-align: center;
            text-decoration: none;
        }

        & .certifies {
            color: #415469;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: 300;
            text-decoration: none;
            font-size: 14pt;
            text-align: center;
        }

        & .name {
            min-width: 460px;
            margin-top: 12px;
            margin-bottom: 6px;
            padding-bottom: 4px;
            padding-left: 24px;
            padding-right: 24px;
            border-bottom: 1px solid black;
            color: #415469;
            font-size: 36pt;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: 600;
            text-decoration: none;
            text-align: center;
        }

        & .achieved {
            color: #415469;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: 300;
            text-decoration: none;
            font-size: 14pt;
            text-align: center;
        }

        & h2 {
            margin-top: 12px;
            margin-bottom: 16px;
            color: #415469;
            font-size: 24pt;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: normal;
            text-align: center;
            text-decoration: none;
        }

        & .directorate {
            color: #415469;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: 300;
            text-decoration: none;
            font-size: 14pt;
            text-align: center;
        }

        & .sign-container {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 720px;

            .sign-cell-container {
                min-width: 250px;

                .field-value {
                    padding-bottom: 8px;
                    border-bottom: 1px solid black;
                    padding-left: 16px;
                    padding-right: 16px;
                    color: #415469;
                    font-size: 11pt;
                    font-family: "Bahnschrift", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: none;
                    text-align: center;

                    .signature {
                        height: 70px;
                        width: auto;
                        margin-left: auto;
                        margin-right: auto;
                        object-fit: contain;
                        margin-bottom: -6px;
                    }
                }

                .field-title {
                    color: #415469;
                    font-size: 9pt;
                    font-family: "Bahnschrift", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: none;
                    text-align: center;
                    height: 40px;
                }
            }
        }

        .referencing-report {
            color: #415469;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: 600;
            text-underline-offset: 8px;
            font-size: 14pt;
            margin-bottom: 32px;
            margin-top: 16px;
        }

        .reference-subtitle {
            color: #415469;
            font-family: "Bahnschrift", sans-serif;
            font-style: normal;
            font-weight: 400;
            text-decoration: none;
            font-size: 10pt;
            margin-top: auto;
            margin-left: auto;
            margin-right: 64px;
            margin-bottom: 8px;
        }

        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }

        table {
            width: 100%;
            height: auto;
            margin-bottom: 16px;

            tr {
                td {
                    text-align: center;
                    min-width: 100px;
                    padding-top: 8px;
                    padding-bottom: 24px;
                    color: #415469;
                    font-family: "Bahnschrift", sans-serif;
                    font-style: normal;
                    font-weight: 300;
                    text-decoration: none;
                    font-size: 12pt;
                    vertical-align: middle;
                }

                &.prominent {
                    td {
                        background-color: #88ABD9;
                        font-weight: 500;
                        color: #415469;
                    }
                }
            }
        }

        .footer-area {
            width: 720px;
            margin-top: 12px;

            .footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                width: 100%;

                &.kids {
                    align-items: stretch;

                    .kids-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .tfal {
                            color: #415469;
                            font-family: "Bahnschrift", sans-serif;
                            font-style: normal;
                            font-weight: 500;
                            text-decoration: none;
                            font-size: 11pt;
                        }
                    }
                }

                .logo {
                    height: 75px;
                    width: auto;
                    object-fit: contain;
                    margin-bottom: 0;
                }

                .malta {
                    height: 70px;
                    width: auto;
                    object-fit: contain;
                    margin-bottom: 0;
                }

                .kid {
                    height: 124px;
                    width: auto;
                    object-fit: contain;
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>